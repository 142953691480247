<template>
  <auth-container>
    <h1 class="mb-3 text-center font-semibold text-black font-roboto text-xlg">
      {{ $t("registration") }}
    </h1>
    <h3 class="text-grey-fontgrey w-96 text-center mb-9">
      {{ $t("registration_text") }}
    </h3>
    <div class="w-96 flex flex-row justify-start items-center mb-7">
      <avatar-bubble
        :backgroundColor="'bg-black'"
        :textColor="'text-white'"
        class="mr-4"
        :name="
          state.user.email
            ? state.user.email
                .split('@')[0]
                .split('.')
                .join(' ')
            : ''
        "
        :size="'9'"
      />
      <h3 class="text-black">{{ state.user.email }}</h3>
    </div>
    <input-text
      class="mb-5 w-96"
      :name="'password'"
      :id="'password'"
      v-model="state.user.password"
      :type="'password'"
      :label="$t('password_new')"
      :labelClass="'bg-white'"
      @keyup.enter="REGISTER(state.user)"
    />
    <input-text
      class="mb-5 w-96"
      :name="'password_confirmation'"
      :id="'password_confirmation'"
      v-model="state.user.password_confirmation"
      :type="'password'"
      :label="$t('password_repeat')"
      :labelClass="'bg-white'"
      @keyup.enter="REGISTER(state.user)"
    />

    <custom-button
      @click="REGISTER(state.user)"
      class="h-11 w-96 "
      :bgColor="'bg-primary'"
      :textColor="'text-white'"
      :text="$t('register')"
      :id="'change-password-button'"
    />
    <div
      class="text-red text-center mt-8 text-sm"
      v-if="state.password_does_not_match == true"
    >
      {{ $t("entered_passwords_do_not_match") }} <br />
      {{ $t("try_again_2") }}
    </div>
  </auth-container>
</template>

<script>
import { defineComponent, reactive } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import AvatarBubble from "../../components/global/AvatarBubble.vue";
import CustomButton from "../../components/input/CustomButton.vue";
import InputText from "../../components/input/InputText.vue";
import { AUTH_TYPES, HELPERS_TYPES, STORE_TYPES } from "../../store/types";
import AuthContainer from "./helpers/AuthContainer.vue";
export default defineComponent({
  name: "ChangePassword",
  components: { AuthContainer, InputText, AvatarBubble, CustomButton },

  setup() {
    const route = useRoute();
    const store = useStore();
    const state = reactive({
      user: {
        fullname: "",
        email: "",
        password: "",
        password_confirmation: "",
      },
      password_does_not_match: false,
    });
    /**
     * functions
     */
    async function REGISTER() {
      store.commit(
        `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.SET_LOADING_ID}`,
        "register-button"
      );
      let userData = {
        token: route.query.token,
        password: state.user.password,
        password_confirmation: state.user.password_confirmation,
      };
      const response = await store.dispatch(
        `${STORE_TYPES.AUTH}/${AUTH_TYPES.REGISTER}`,
        userData
      );
      if (response == false) {
        state.password_does_not_match = true;
      }
      store.commit(
        `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.REMOVE_LOADING_ID}`,
        "register-button"
      );
      if (response == true) {
        store.dispatch(`${STORE_TYPES.AUTH}/${AUTH_TYPES.FETCH_USER}`);
      }
    }

    // token from url
    async function FETCH_TOKEN_USERINFORMATION() {
      const response = await store.dispatch(
        `${STORE_TYPES.AUTH}/${AUTH_TYPES.FETCH_TOKEN_USERINFORMATION}`,
        route.query.token
      );
      state.user.fullname = response.fullname;
      state.user.email = response.email;
    }

    /**
     *  Created
     */

    store.commit(
      `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.SET_LOADING_ID}`,
      "isLoadingData"
    );
    Promise.all([FETCH_TOKEN_USERINFORMATION()]).then(() => {
      store.commit(
        `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.REMOVE_LOADING_ID}`,
        "isLoadingData"
      );
    });

    return {
      state,
      REGISTER,
    };
  },
});
</script>

<style></style>
